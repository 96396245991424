/**
 * This directive scrolls the document to the first child
 * matching the given selector
 */
angular.module("aerosApp")
.directive('scrollTo', ["$timeout", function ($timeout) {
	function scrollTo($element, $scrollParent){
		$scrollParent = $scrollParent || $('body, html');
		var pos = $element.position().top;
		$scrollParent.animate({
			scrollTop : pos			  			              
		});
	};
	return {
		restrict: 'A',
		scope: {
			scrollTo: "@"
		},
		link: function(scope, element, attrs) {
			var selector = attrs.scrollTo;
			var $element = $(element);
			
		    scope.$watch(
	    		function(){ return $element.children().size()},
	    		function(newSize){
	    			$timeout(function(){
	    				var $children = $element.find(selector);	    			
	    				if($children.length > 0){	    				
	    					scrollTo($children.first());		    	
	    				}
	    			});	    				
	    		}
		    );		    
		}
	}
}]); 
